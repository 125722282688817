<template>
  <div class="chat-bubble">
    <div class="chat-header" style="color:red; font-weight: bold; text-align: center;">
      <p>CHAT Qinaya</p>
    </div>
    <div class="messages">
      <div v-for="(msg, index) in allMessages" :key="index" :class="['message', msg.type]">
        {{ msg.text }}
      </div>
    </div>
    <div class="chat-input">
      <input v-model="newMessage" placeholder="Type a message..." @click="focusKeyboard" />
      <button @click="sendMessage">Send</button>
    </div>
  </div>
</template>

  
<script>
export default {
  name: 'ChatBubble',
  data() {
    return {
      newMessage: '',
      allMessages: [], // Combined messages
    };
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside, true);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside, true);
  },
  methods: {
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.$emit('close');
      }
    },
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        // Add the new message as 'sended'
        this.allMessages.push({ text: this.newMessage, type: 'sended' });
        this.newMessage = ''; // Clear input field

        // Scroll to bottom to show new message
        this.$nextTick(() => {
          const messagesDiv = this.$el.querySelector('.messages');
          messagesDiv.scrollTop = messagesDiv.scrollHeight;
        });

        // Simulate a received message after a short delay
        setTimeout(() => {
          // Add the received message
          this.allMessages.push({ text: 'Received: ' + Math.random().toString(36).substring(7), type: 'received' });
          
          // Scroll to bottom to show new message
          this.$nextTick(() => {
            const messagesDiv = this.$el.querySelector('.messages');
            messagesDiv.scrollTop = messagesDiv.scrollHeight;
          });
        }, 1000);
      }
    },
    focusKeyboard() {
      this.$emit('disconnectKeyboard')
    }
  }
}
</script>
  
<style>
.chat-bubble {
  position: fixed;
  height: 300px;
  width: 250px; /* Adjust as needed */
}

.messages {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 5px;
}

.message {
  padding: 5px 10px;
  border-radius: 12px;
  width: 80%;
  word-break: break-all;
}

.sended {
  align-self: flex-end;
  background-color: #dcf8c6; /* Light green */
}

.received {
  align-self: flex-start;
  background-color: #ececec; /* Light gray */
}

.chat-input {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.messages {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 5px;
  max-height: 80%; /* Adjust as needed */
}

.chat-input input {
  flex-grow: 1;
  margin-right: 5px;
  padding: 5px;
  border-radius: 12px;
  border: 1px solid #ccc;
}

.chat-input button {
  padding: 5px 10px;
  border-radius: 12px;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: darkred;
}
</style>