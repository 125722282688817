<template>
  <div>
    <div :style="menuStyle" class="floatingMenu">
      <div
        class="menuHandle"
        style="display: flex; flex-direction: column; justify-items: center; align-items: center"
      >
        <!-- Draggable handle with SVG -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          style="filter: invert(1)"
          transform="scale(2)"
          @click.stop="toggleMenu"
        >
          <path d="M9 14h6v1H9v-1zm0-4h6v1H9v-1zm0-4h6v1H9V6z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          @mousedown="dragStart"
          @touchstart="dragStart"
          style="margin-top: 5px; filter: invert(1)"
          transform="scale(1)"
        >
          <path
            d="M18.364 8.465 16.95 9.879 18.071 11H13V5.928l1.122 1.122 1.414-1.414L12 2.101 8.464 5.636 9.878 7.05 11 5.928V11H5.929L7.05 9.879 5.636 8.465 2.1 12l3.536 3.535 1.414-1.414L5.929 13H11v5.072L9.878 16.95l-1.414 1.414L12 21.899l3.536-3.535-1.414-1.414L13 18.072V13h5.071l-1.121 1.121 1.414 1.414L21.9 12l-3.536-3.535z"
          />
        </svg>
      </div>
      <div v-if="isMenuOpen" class="menuContent">
        <!-- <div @click="$emit('afullscreen')" class="menuItem">Full Screen</div> -->
        <div @click="openChat" class="menuItem">Chat</div>
        <div @click="fullscreen" class="menuItem">Fullscreen</div>
        <div @click="logOut" class="menuItem">Logout</div>
      </div>
      <ChatBubble
        :style="{ top: menuPosition.top + 'px', right: menuPosition.right + 120 + 'px' }"
        v-show="isChatBubbleVisible"
        @disconnectKeyboard="this.$emit('disconnectKeyboard')"
      />
    </div>
    <div
      v-if="isDragging"
      style="
        background-color: transparent;
        width: 100%;
        height: 100vh;
        position: absolute;
        z-index: 1040;
      "
    ></div>
  </div>
</template>

<script>
import ChatBubble from './chat-bubble.vue'

export default {
  name: 'FloatingMenu',
  data() {
    return {
      isMenuOpen: false,
      menuPosition: { top: 100, right: 50 },
      isDragging: false,
      isChatBubbleVisible: false
    }
  },
  components: {
    ChatBubble
  },
  computed: {
    menuStyle() {
      return {
        right: `${this.menuPosition.right}px`,
        top: `${this.menuPosition.top}px`
      }
    }
  },
  methods: {
    logOut() {
      this.isMenuOpen = false
      this.$emit('logout')
    },
    toggleChatBubble() {
      this.isChatBubbleVisible = !this.isChatBubbleVisible
    },
    fullscreen() {
      this.$emit('fullscreen')
    },
    openChat() {
      this.toggleChatBubble()
      this.$emit('disconnectKeyboard')
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
      if (this.isChatBubbleVisible) {
        this.toggleChatBubble()
      }
    },
    dragStart(event) {
      this.isDragging = true
      const clientX = event.type.includes('touch') ? event.touches[0].clientX : event.clientX
      const clientY = event.type.includes('touch') ? event.touches[0].clientY : event.clientY
      this.startX = clientX
      this.startY = clientY

      // Add both mouse and touch events
      document.addEventListener('mousemove', this.dragMove)
      document.addEventListener('mouseup', this.dragEnd)
      document.addEventListener('touchmove', this.dragMove, { passive: false }) // Use passive: false to allow preventDefault
      document.addEventListener('touchend', this.dragEnd)

      event.preventDefault() // Prevent default action to avoid issues like scrolling
    },
    dragMove(event) {
      if (!this.isDragging) return

      const clientX = event.type.includes('touch') ? event.touches[0].clientX : event.clientX
      const clientY = event.type.includes('touch') ? event.touches[0].clientY : event.clientY

      const dx = clientX - this.startX
      const dy = clientY - this.startY
      this.menuPosition.right -= dx
      this.menuPosition.top += dy

      this.startX = clientX
      this.startY = clientY

      event.preventDefault() // This is to avoid scrolling and other default touch behaviors
    },
    dragEnd() {
      this.isDragging = false
      document.removeEventListener('mousemove', this.dragMove)
      document.removeEventListener('mouseup', this.dragEnd)
      document.removeEventListener('touchmove', this.dragMove)
      document.removeEventListener('touchend', this.dragEnd)
    }
  }
}
</script>

<style>
.floatingMenu {
  position: fixed;
  z-index: 1050; /* Above most content */
  background-color: #000000a0; /* Semi-transparent black */
  color: white;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  user-select: none; /* Prevent text selection */
}

.menuHandle {
  display: flex;
  justify-content: center;
  margin-bottom: 5px; /* Spacing between handle and menu content */
}

.menuContent {
  display: flex;
  flex-direction: column;
}

.menuItem {
  padding: 5px 10px;
  text-align: center;
  border-top: 1px solid #ffffff50; /* Light border for separating items */
}

.menuItem:first-child {
  border-top: none;
}

.menuItem:hover {
  background-color: #ffffff25; /* Light hover effect */
}
</style>
