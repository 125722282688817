<template>
  <main>
    <login v-if="!loginAutorized && !guacamoleStarted && !route.query.token " @login-success="autorizedLogin" />
    <!-- <button v-if="!guacamoleStarted && route.query.token" @click="startGuacamole">Start</button> -->
    <GuacamoleStage v-if="guacamoleStarted" :encryptedToken="token" :userData="userData" @display-login="displayLogin"/>
  </main>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router' // Import useRoute from vue-router

import GuacamoleStage from '../components/guacamole-client.vue'
import login from '@/components/login.vue'

const guacamoleStarted = ref(false)
const userData = ref(null)
const token = ref(null)
const loginAutorized = ref(false)

const route = useRoute()

// function startGuacamole() {
  token.value = route.query.token
  if (token.value) {
    guacamoleStarted.value = true
  } else {
    loginAutorized.value = false
  }
// }

function autorizedLogin(data) {
  loginAutorized.value = true
  userData.value = data
  guacamoleStarted.value = true
}

async function displayLogin() {
  loginAutorized.value = false
  guacamoleStarted.value = false
}
</script>
